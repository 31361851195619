@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");

*, body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  user-select: text;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -moz-touch-callout: none;
  -moz-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-apperance: 0;
  -moz-apperance: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: "Noto Sans KR", sans-serif;
}

#root{
  background-color: #f8f8f8;
}

.app{
  max-width:1000px;
  width:1000px;
  margin:0 auto;
  min-height:100vh;
}

a, a:link{
  outline: none;
  text-decoration: none;
}

li{
  list-style: none;
}

button{
  border:none;
  background-color:rgba(0,0,0,0);
}

i{
  font-style: normal;
}

input{
  background-color:rgba(0,0,0,0);
  border:none;
  outline: none;
}